import React from 'react';
import Typewriter from 'typewriter-effect';
import './Console.css';

const CONSOLE_START = '$>';

const LINE_1 = 'cat portfolio.txt';
const LINE_2 = '&nbsp;_&nbsp;&nbsp;&nbsp;&nbsp;_&nbsp;_____&nbsp;_&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;_____&nbsp;________&nbsp;&nbsp;________';
const LINE_3 = '| |&nbsp; | |&nbsp; ___| |&nbsp;&nbsp; /&nbsp; __ \\&nbsp; _&nbsp; |&nbsp; \\/&nbsp; |&nbsp; ___|';
const LINE_4 = '| |&nbsp; | | |__ | |&nbsp;&nbsp; | /&nbsp; \\/ | | | .&nbsp; . | |__';
const LINE_5 = '| |/\\| |&nbsp; __|| |&nbsp;&nbsp; | |&nbsp;&nbsp; | | | | |\\/| |&nbsp; __|';
const LINE_6 = '\\&nbsp; /\\&nbsp; / |___| |___| \\__/\\ \\_/ / |&nbsp; | | |___';
const LINE_7 = '&nbsp;\\/&nbsp; \\/\\____/\\_____/\\____/\\___/\\_|&nbsp; |_|____/';
const LINE_8 = '--------------------------------------------';
const LINE_9 = '- Name: Stéphane Bottin';
const LINE_10 = '- Profession: Fullstack Engineer';
const LINE_11 = '- Contact: stephaneb.business@gmail.com';
const LINE_12 = '--------------------------------------------';
const LINE_13 = 'Where do you want to start your journey?';
const LINE_14 = '[A] Projects';
const LINE_15 = '[B] Skills';
const LINE_16 = '[C] Hobbies';
const LINE_17 = '<i>Please type the letter corresponding to your choice then press enter...</i>';

interface ConsoleProps {
    doneWriting: () => void;
}

function ConsoleLine({ doneWriting }: ConsoleProps) {
  return (
    <div className='console-line'>
        <Typewriter
          onInit={(typewriter) => {
            typewriter
                .changeDelay(1)
                .pasteString(CONSOLE_START + ' ', null)
                .changeDelay(150)
                .typeString(LINE_1 + '<br>')
                .changeDelay(1)
                .typeString(LINE_2 + '<br>')
                .typeString(LINE_3 + '<br>')
                .typeString(LINE_4 + '<br>')
                .typeString(LINE_5 + '<br>')
                .typeString(LINE_6 + '<br>')
                .typeString(LINE_7 + '<br>')
                .typeString(LINE_8 + '<br>')
                .typeString(LINE_9 + '<br>')
                .typeString(LINE_10 + '<br>')
                .typeString(LINE_11 + '<br>')
                .typeString(LINE_12 + '<br>')
                .typeString(LINE_13 + '<br>')
                .typeString(LINE_14 + '<br>')
                .typeString(LINE_15 + '<br>')
                .typeString(LINE_16 + '<br>')
                .typeString(LINE_17 + '<br>')
                .pasteString(CONSOLE_START + ' ', null)
                .callFunction(() => {
                    doneWriting();
                })
                .start();
          }}
        />
    </div>
  );
}

export default ConsoleLine;
