import React, { useState, useEffect, useRef } from 'react';
import VanillaTilt from 'vanilla-tilt';
import Typewriter from 'typewriter-effect';
import './Card.css';


function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    if (tilt && tilt.current) {
        VanillaTilt.init(tilt.current, options);
    }
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

interface Link {
    title: string,
    icon: string,
    ref: string,
}

interface Card {
    title: string,
    date: string,
    tags: string[],
    description: string,
    background?: string,
    image: string,
    links: Link[],
}

interface CardProps {
    card: Card,
    index: number
}

interface TagProps {
    tag: string,
    i: number
}

const Tag = ({tag, i}: TagProps) => {
    let color = '#636363'; // grey

    switch (tag) {
        // Projects
        case 'game': color = '#00A7FF'; break;
        case 'PC': color = '#B900FF'; break;
        case 'app': color = '#FF00AC'; break;
        case 'mobile': color = '#FF4000'; break;
        case 'social platform': color = '#21C455'; break;
        case 'e-commerce': color = '#12E25D'; break;
        case 'e-learning': color = '#12E29D'; break;
        case 'web': color = '#3813EF'; break;
        case 'web3': color = '#EF1357'; break;
        case 'software': color = '#F68D01'; break;
        case 'fashion': color = '#E0D114'; break;
        // Hobbies
        case 'photography': color = '#B900FF'; break;
        case 'video editing': color = '#F68D01'; break;
        case 'sports': color = '#EF1357'; break;
        case 'music': color = '#12E29D'; break;
        // Skills
        case 'frontend': color = '#B900FF'; break;
        case 'backend': color = '#F68D01'; break;
        case 'devops': color = '#EF1357'; break;
        case 'database': color = '#12E29D'; break;
    }

    return <span key={i} style={{ backgroundColor: color}}>{tag}</span>;
}

const Card = ({ card, index }: CardProps) => {
    const [title, setTitle] = useState(card.date ?? '');
    const options = {
        scale: 1,
        speed: 1000,
        max: 30,
        glare: true,
    };

    const handleOnHover = (text) => {
        setTitle(text);
    }

    const handleOnExit = () => {
        setTitle(card.date ?? '');
    }

    const handleOnClick = (link) => {
        if (link.includes('https')) {
            window.open(link, '_blank');
        } else {
            window.open(link, '_self');
        }
    }

    return (
     <div key={index} className='project-container'>
        <div className='date console-line'>
            <Typewriter
              options={{
                strings: [title],
                autoStart: true,
                delay: 20,
                loop: true,
                deleteSpeed: 5,
              }}
            />
        </div>

        <Tilt options={options} className='project'>
            <div>
                <img src={'../img/' + card.image}/>
                <div className='details'>
                    <div>
                        <div className='fade'></div>
                        <div className='tags'>
                            { card.tags.map((tag, index) => <Tag i={index} tag={tag}/>) }
                        </div>
                        <h1 style={card.title.length > 20 ? {fontSize: '21px', marginBottom: '10px'} : {}}>{card.title}</h1>
                        <p>{card.description}</p>
                    </div>
                    <div className='socials'>
                        { card.links.map((link, index) => <img key={index}
                            onClick={() => handleOnClick(link.ref)}
                            onMouseOut={handleOnExit}
                            onMouseOver={() => handleOnHover(link.title)}
                            className='social'
                            src={'../img/' + link.icon}/>) }
                    </div>
                </div>
            </div>
        </Tilt>
     </div>);
}

export default Card;
