import React, { useState } from 'react';
import ConsoleLine from './ConsoleLine';
import './Console.css';

interface ConsoleProps {
    menuSelection: (value: string) => void;
}

function Console({ menuSelection }: ConsoleProps) {
    const [doneWriting, setDoneWriting] = useState(false);

    const handleDoneWriting = () => {
        setDoneWriting(true);
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            const value: string = (event.target as HTMLInputElement).value.toUpperCase();
            menuSelection(value[0]);
        }
    }

    return (
        <div className='console-bg crt'>
            <div className='console-lines-container'>
                <ConsoleLine doneWriting={handleDoneWriting}/>
                {doneWriting && <input type='text' autoFocus onKeyDown={handleKeyDown}/>}
            </div>
        </div>
    );
}

export default Console;
