import React, { useState } from 'react';
import ConsoleSingleLine from './ConsoleSingleLine';
import Card from './Card';
import './Console.css';

interface MonitorProps {
    consoleLine: string;
    cards: Card[];
}

function Monitor({ consoleLine, cards }: MonitorProps) {
    const cardsList = [...cards].reverse();
    const [doneWriting, setDoneWriting] = useState(false);

    const handleDoneWriting = () => {
        setDoneWriting(true);
    }

    return (
        <div className='projects-bg'>
            <div className='console-lines-container'>
                <ConsoleSingleLine line={consoleLine} doneWriting={handleDoneWriting}/>
            </div>

            {doneWriting && <div className='projects-container'>
                { cardsList.map((card, index) => <Card card={card} index={index}/>) }
            </div>}
        </div>
    );
}

export default Monitor;
