import React, { useState, useEffect } from 'react';
import Console from './component/Monitor/Console';
import Monitor from './component/Monitor/Monitor';
import './App.css';

import projectsData from './data/projects.json';
import hobbiesData from './data/hobbies.json';
import skillsData from './data/skills.json';

function App() {
    const queryParams = new URLSearchParams(window.location.search)
    const page = queryParams.get("page") ?? '';

    useEffect(() => {
       document.title = "Steph's Portfolio"
    }, []);

    const goToPage = (page: string) => {
        const { href } = window.location;
        window.location.href = `${href}?page=${page}`;
    }

    const handleMenuSelection = (value: string) => {
        if (!['A', 'B', 'C'].includes(value)) {
            return;
        }

        switch (value) {
            case 'A': goToPage('projects'); break;
            case 'B': goToPage('skills'); break;
            case 'C': goToPage('hobbies'); break;
        }
    }

    return (
        <div className="App">
            {page === '' && <Console menuSelection={handleMenuSelection} />}
            {page === 'projects' && <Monitor cards={projectsData} consoleLine={'python3 projects.py'}/>}
            {page === 'skills' && <Monitor cards={skillsData} consoleLine={'gcc mySkills.c -o skills.exe; skills.exe'}/>}
            {page === 'hobbies' && <Monitor cards={hobbiesData} consoleLine={'java Hobbies.java'} />}
        </div>
    );
}

export default App;
