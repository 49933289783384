import React from 'react';
import Typewriter from 'typewriter-effect';
import '../Monitor/Console.css';

const CONSOLE_START = '$>';

const LINE_1 = 'python3 projects.py';

interface ConsoleProps {
    line: string;
    doneWriting: () => void;
}

function ConsoleLine({ line, doneWriting }: ConsoleProps) {
  return (
    <div className='console-line'>
        <Typewriter
          onInit={(typewriter) => {
            typewriter
                .changeDelay(1)
                .pasteString(CONSOLE_START + ' ', null)
                .changeDelay(50)
                .typeString(line + '<br>')
                .callFunction(() => {
                    doneWriting();
                })
                .start();
          }}
        />
    </div>
  );
}

export default ConsoleLine;
